footer.footer {
  background-color: var(--footer-bg);
  color: var(--footer-fg);
  padding: 3.5rem 0px;
}

.footer__content {
  display: flex;
  gap: 2rem;
  justify-content: space-between;
  flex-wrap: wrap;
}

.footer__content-left {
  display: flex;
  gap: 5rem;
  justify-content: space-between;
  flex-direction: column;
}
.footer__content-right {
}

nav.meta-nav {
}
ul.meta-nav__list {
}
li.meta-nav__item {
}

.footer__social {
  display: inline-flex;
  gap: 1rem;
  flex-wrap: wrap;
}

.footer__social-link {
  display: block;
}

.footer__newsletter {
  border-bottom: 1px solid var(--footer-fg);
  display: flex;
  align-items: center;
  gap: 2rem;
  padding-bottom: 0.5rem;

  --fill: var(--footer-fg);
  --stroke: var(--footer-fg);

  .icon {
    color: var(--dark);
  }

  &:hover {
    border-bottom: 1px solid var(--footer-hover-fg);
    --fill: var(--footer-hover-fg);
    --stroke: var(--footer-hover-fg);
    .icon {
      color: var(--red);
    }
  }
}
