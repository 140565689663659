.filter-overlay {
  padding-top: var(--header-height);
  background-color: var(--overlay-bg);
  color: var(--overlay-fg);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: var(--z-overlay);
  padding: calc(2.5rem + var(--header-height)) 0px 5rem 0px;
  box-shadow: var(--box-shadow-2);

  &.animated {
    transition: transform 200ms ease-in-out;
  }
}

.filter-overlay__header {
  display: flex;
  justify-content: flex-end;
}

.filter__wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.filter__title {
  margin-bottom: 1.5rem;
  margin-top: 3.5rem;
}

label.filter__label {
  cursor: pointer;
  display: block;

  input {
    position: absolute;
    visibility: hidden;
    top: 0;
    left: 0;
    z-index: var(--z-hidden);
  }

  input:checked ~ .filter__button {
    background-color: var(--button-bg);
    border-color: var(--button-bg);
    color: var(--button-fg);
    .icon {
      display: block;
    }
  }
}

.filter__button {
  border-radius: 1.5rem;
  border: 1px solid var(--dark);
  padding: 11px 17px;
  display: flex;
  align-items: center;
  gap: 0.5rem;

  .icon {
    display: none;
  }
}

.filter__button--all.active {
  background-color: var(--button-bg);
  border-color: var(--button-bg);
  color: var(--button-fg);
  .icon {
    display: block;
  }
}

#filter-overlay-toggle:checked ~ main {
  .filter-overlay {
    transform: translateY(0%) !important;
  }
}

#filter-overlay-toggle {
  position: absolute;
  left: 0;
  top: 0;
  visibility: hidden;
  z-index: var(--z-hidden);
}
