/* styling nicht für andere Seiten verwenden */

body.layout--signup {
  --header-height: 128px;
  --header-content-height: 128px;
  --collapsible-padding: 1.25rem;

  --srgd-red-light: rgb(227, 31, 43);
  --srgd-red: rgb(211, 2, 40);
  --srgd-red-dark: rgb(175, 0, 29);
  --srgd-gray-light: rgb(237, 237, 237);
  --srgd-gray: rgb(210, 210, 210);
  --srgd-gray-dark: rgb(150, 150, 150);

  --primary-color: var(--srgd-red-light);
  --black: #000000;

  --bg: var(--black);

  @media (--media-mobile) {
    /* --header-content-height: 64px; */
  }

  header.header {
    position: absolute;
    background: transparent;
  }

  .header__content {
    height: unset;
    margin-top: 1.5rem;

    @media (--media-md) {
      margin-top: 4.5rem;
    }
  }

  .header__content-logo img {
    height: 6rem;
  }

  /* .header .container, */
  .member__overlay:not(.member__overlay--auto) .container {
    max-width: none;
  }

  .member__overlay[data-video-wrapper] {
    margin: 0px;
    padding: 0px;
  }

  .member__overlay:not(.member__overlay--auto) .container {
    margin: 0px;
    padding: 0px;
  }

  p:not([class]) {
    /* font-size: 1rem; */
    /* font-weight: 400; */
    /* line-height: 1.4rem; */
    font-weight: 300;
    margin-bottom: 0.7rem;
  }

  @media (--media-xl) {
    p {
      font-size: 1.2222222222rem;
      line-height: 1.6111111111rem;
      margin-bottom: 2rem;
    }
  }

  strong {
    font-weight: 400;
  }

  .signup__logo-container {
    display: inline-flex;
    align-items: flex-end;
    /* padding: 1rem 0px; */
    /* transition: all 0.4s ease; */
    /* height: $member__header-small; */

    /* body.scrolled & {
    height: $member__header-small-scrolled;
  } */
  }

  .member__overlay {
    margin-top: 0px !important;
    position: relative;
    height: 100vh;
    width: 100%;
    background-color: var(--dark);

    /*
    @media (--media-lg) {
      height: calc(100vh - var(--header-height, 80px));
    }
    @media (--media-xl) {
      height: calc(100vh - var(--header-height, 85px));
    } */

    &.member__overlay--auto {
      height: auto;
    }

    /*special h4s in overlay */
    & h4 {
      font-weight: 900;
      line-height: 130%;

      & + p {
        margin-top: 1rem;
      }
    }
  }

  .member__inner-wrapper {
    position: relative;
    width: 100%;
    height: 100vh;
    z-index: 1;
    pointer-events: none;
    transition: opacity 0.4s ease;
  }

  .member__content-box {
    position: absolute;
    padding: 2rem;
    right: 0;
    bottom: 0;
    pointer-events: auto;

    @media (--media-md) {
      max-width: 35rem;
    }
  }

  .member__button {
    margin-top: 2rem;
    a {
      width: 100%;
      display: block;
    }
  }

  .member__title,
  .member__button {
    background-color: var(--primary-color);
    font-size: 26px;
    font-weight: 700;
    line-height: normal;
    padding: 0.35rem;
    &,
    a {
      color: var(--white);
      text-decoration: none;
    }
  }

  .member__content {
    span {
      display: block;
      padding: 1.5rem;
    }
  }

  .member__content p,
  .message p {
    font-weight: 500;
    margin-bottom: 0;
  }

  .member__content--title {
    margin-bottom: 1rem;
    background-color: var(--white);
    color: var(--red);
  }

  .member__content--message {
    background-color: var(--red);
    color: var(--white);
  }

  .member__image-cover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: var(--black);
  }

  .member__image-container {
    height: 100vh;
    position: relative;

    /* @media (--media-md) {
      height: 100%;
    } */
  }

  .member__image {
    position: absolute;
    object-fit: cover;
    height: 100%;
    width: 100%;
  }

  .section-scroll {
    transition: opacity 0.4s ease;
  }

  /* .section-button {
    z-index: 2;
    position: fixed;
    background: var(--primary-color)
      url("../static/member/icons8-expand-arrow-24.png") center center no-repeat;
    border-radius: 40px;
    height: 40px;
    width: 40px;
    transition: all 200ms ease-in-out;
  }

  .section-button--back {
    bottom: 4rem;
    right: 1rem;
    transform: rotate(180deg);
    @media (--media-md) {
      bottom: 7rem;
      right: 4rem;
    }
    &.disabled {
      cursor: default;
      opacity: 0.6;
    }
    &:hover:not(.disabled) {
      background-color: var(--srgd-red-dark);
    }
  }

  .section-button--next {
    bottom: 1.25rem;
    right: 1rem;
    @media (--media-md) {
      bottom: 4rem;
      right: 4rem;
    }
    &.disabled {
      cursor: default;
      opacity: 0.6;
    }
    &:hover:not(.disabled) {
      background-color: var(--srgd-red-dark);
    }
  } */

  .section-scroll--hidden {
    display: none;
  }

  .member__form {
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    h1 {
      font-size: 90px;
    }
  }
  .member-video__container,
  .member__overlay:not(.member__overlay--auto) .container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    background: #000;
  }

  .member-video__controls {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .cmp-embed {
    .member-video__consent {
      background: rgba(0, 0, 0, 0.7);
      color: white;
      padding: 2rem;
      position: absolute;
    }
    @media (--media-md) {
      .member-video__consent {
        top: 20%;
        left: 25%;
        width: 50%;
      }
    }

    .cmp-embed__consent,
    .cmp-embed__error {
      background: black;
      color: white;
      padding: 2rem;
    }
  }

  .member-video__play {
    position: absolute;
    z-index: 3;
    height: 80px;
    cursor: pointer;

    top: calc((100vh - 420px) / 2);
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    user-select: auto;

    img {
      height: 100%;
    }

    @media (--media-md) {
      top: 50%;
      height: 100px;
    }
  }

  .member-video__pause {
    position: absolute;
    bottom: 5%;
    right: 5%;
    transform: translateX(-50%) translateY(-50%);
    z-index: 3;
    width: 50px;
    height: 50px;
    cursor: pointer;
    opacity: 0;
    user-select: none;
  }

  .member-video__fs {
    position: absolute;
    bottom: 5%;
    right: 20%;
    transform: translateX(-50%) translateY(-50%);
    z-index: 3;
    width: 50px;
    height: 50px;
    cursor: pointer;
    opacity: 0;
    user-select: none;
    @media (--media-md) {
      display: none;
    }
  }

  .member-video {
    position: absolute;
    left: 50%;
    transform: translateX(-50%) translateY(0);
    cursor: pointer;
    user-select: auto;
    min-width: 100%;
    min-height: 100%;

    @media (--media-md) {
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
    }
  }

  .copyright--keystone {
    position: absolute;
    bottom: 0;
    z-index: 4;
    color: var(--white);
    left: 5px;
    font-size: 12px;
    @media (--media-sm-only) {
      text-align: right;
      transform-origin: right top;
      transform: rotate(90deg);
      left: unset;
      right: -2px;
      bottom: -20px;
    }
  }

  .answer-list__logo {
    height: 25px;
    width: auto;
    display: block;
  }

  .foldout-regions__toggle,
  .foldout-referrer__toggle {
    height: 100%;
    margin-left: 0.5rem;

    .toggled & {
      transform: rotate(180deg);
    }
  }

  .foldout-trigger {
    cursor: pointer;
    display: inline-flex;
    gap: 0.5rem;
    align-items: center;
    margin-block: 2rem;
  }

  .js-foldout-wrapper {
    height: 0;
    overflow: hidden;

    transition: height 0.3s ease-in-out;
  }

  .collapsible {
    margin-bottom: 1rem;
  }

  .collapsible__header {
    background-color: var(--srgd-gray-light);
    padding: var(--collapsible-padding);
    padding-bottom: 0.25rem;
    display: flex;
    cursor: pointer;
    transition: background-color 0.3s;
    &:hover {
      background-color: var(--srgd-gray);
    }

    .collapsible__title p {
      margin-bottom: 1rem !important;
    }
  }

  .collapsible__content {
    background: var(--srgd-gray-light);
    padding: var(--collapsible-padding);
  }

  .signup-region-radios {
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: 1fr;
    gap: 0px 1.5rem;

    @media (--media-md) {
      grid-template-columns: 1fr 1fr;
    }
  }

  .zip-error {
    display: block;
    margin-top: 0.5rem;
    margin-bottom: 1.5rem;
    color: var(--srgd-red);
  }

  .signup-form__zip-input {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    gap: 1rem;

    input {
      width: auto;
    }

    .form__item {
      margin-bottom: 0px;
      width: auto;
    }
  }

  .signup-region-radios {
    margin-bottom: 30px;

    img {
      height: 28px;
      width: auto;
      max-width: 236px;
      background-repeat: no-repeat;
      background-size: contain;
      pointer-events: none;
    }

    label.answer-list__label {
      display: block;
    }

    /* label.answer-list__label--png {
      background-color: var(--white);
    } */

    /* input[type="radio"]:not(old) + label:before {
    background: var(--srgd-gray);
  }

  input[type="radio"]:not(old):checked + label:before {
    background: var(--black);
    border: 6px solid var(--srgd-gray);
  } */

    @media (--media-xl) {
      img {
        height: 32px;
        max-width: 242px;
      }

      /* input[type="radio"]:not(old) + label:before {
      top: 4px;
    } */
    }
  }

  p.delimiter {
    border-top: 1px solid var(--srgd-gray);
    border-bottom: 1px solid var(--srgd-gray);
    padding: 0.7777777778rem 0;
    margin-block: 2rem;
  }

  .inner-space-top-2 {
    padding-top: 4.4444444444rem;
  }

  .hide {
    display: none;
  }

  .errorlist {
    /* margin-top: 0.16667rem; */
    /* font-size: 0.83333rem !important; */
    margin-top: 0.25rem !important;
    font-size: 1.25rem !important;
    color: var(--srgd-red);
    background: none;
  }

  .errorlist {
    margin: 0 0 1em;
    li:before {
      display: none;
    }
  }
}
