.buttons {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  gap: 1.5rem;
}

button {
  padding: 0;
  overflow: visible;
  border: 0;
  font: inherit;
  -webkit-font-smoothing: inherit;
  letter-spacing: inherit;
  color: inherit;
  background: none;
  cursor: pointer;
  appearance: none;
}

.button {
  display: inline-block;
  vertical-align: middle;
  user-select: none;
  text-decoration: none;
  cursor: pointer;
  border-radius: 0.5rem;
  border: none;
  color: var(--button-fg);
  background-color: var(--button-bg);
  padding: 12px 24px;

  transition: color 200ms ease-in-out, background-color 200ms ease-in-out;

  &:hover {
    color: var(--button-hover-fg);
    background-color: var(--button-hover-bg);
  }
}

.button--inverted {
  color: var(--button-bg);
  background-color: var(--button-fg);

  &:hover {
    color: var(--button-hover-bg);
    background-color: var(--button-hover-fg);
  }
}

.button-icon {
  display: block;
  height: 1.5rem;
  width: 1.5rem;
  color: var(--icon);

  transition: color 200ms ease-in-out, fill 200ms ease-in-out, stroke 200ms
    ease-in-out;

  &:hover {
    color: var(--icon-hover);
  }
}

.button-icon--round {
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  color: var(--dark);
  background-color: var(--icon);
  display: flex;
  justify-content: center;
  align-items: center;

  transition: color 200ms ease-in-out, fill 200ms ease-in-out, stroke 200ms
    ease-in-out, background-color 200ms ease-in-out;

  &:hover {
    color: var(--icon);
    background-color: var(--icon-hover);
  }
}

.button-icon--rotate {
  .icon {
    transform: rotate(180deg);
  }
}

.button--cta {
  padding: 1rem;
}

.button--right {
  margin-left: auto;
  display: block;
}

section.related-section .button {
  margin-top: 1.2rem;
}
