.responsive-embed {
}

.srfplay,
.vimeo,
.youtube {
  width: 100%;
  height: 100%;
  padding: 56.25% 0 0 0;
  position: relative;

  iframe {
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    width: 100%;
    height: 100%;
  }
}

/* widescreen  */
