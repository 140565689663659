.related {
  display: flex;
  flex-wrap: wrap;
  gap: var(--grid-gap);
}

article.related__item {
  --width: 100% flex: 0 0 var(--width);
  max-width: var(--width);

  @media (--media-md) {
    --width: calc((100% / 2) - (1 * var(--grid-gap) / 2));
  }

  @media (--media-lg) {
    --width: calc((100% / 3) - (2 * var(--grid-gap) / 3));
  }
}

.related__item-link {
}

.related__item-image-wrapper {
  position: relative;
}

.related__item-image {
  border-radius: 0.5rem;
}

.related__media-icons {
  position: absolute;
  right: 1rem;
  bottom: 1rem;
  display: flex;
  gap: 0.25rem;
}

.related__item-title {
  margin-top: 1.25rem;
}
