.embed-unavailable,
.cmp-embed {
}

.cmp-embed__initializing {
  padding: 2rem;
  background: center no-repeat url("../assets/ajax-loader.gif") transparent;
}

.embed-unavailable,
.cmp-embed__consent,
.cmp-embed__error {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: justify;
  clear: both;
  padding: 2rem;
  background: var(--button-bg);
  color: var(--button-fg);
  --link-hover: var(--dark);
}
