:root {
  --white: #ffffff;
  --dark: #333333;

  --red: #af001d;
  --bg-green: #135658;
  --dark-green: #27b0b4;
  --green: #1b7b7e;
  --light-green: #68c8cb;

  /* --link-fg: var(red); */
}

/* -------------------------------------------------------------------------------------------
-----------------------------------          SRGD           ----------------------------------
------------------------------------------------------------------------------------------- */
body.template--srgd {
  --fg: var(--white);
  --bg: var(--dark);

  --accent-fg: var(--red);
  --accent-bg: var(--red);
  --accent-hover-fg: var(--dark);

  --link-hover: var(--red);

  --button-fg: var(--white);
  --button-bg: var(--red);
  --button-hover-fg: var(--dark);
  --button-hover-bg: var(--white);

  --icon: var(--white);
  --icon-hover: var(--red);

  --navi-fg: var(--dark);
  --navi-bg: var(--white);
  --navi-hover-fg: var(--red);

  @media (--media-mobile) {
    --navi-fg: var(--white);
    --navi-bg: var(--dark);
  }

  --overlay-fg: var(--dark);
  --overlay-bg: var(--white);

  --footer-fg: var(--white);
  --footer-bg: var(--red);
  --footer-hover-fg: var(--dark);

  footer.footer {
    --link-hover: var(--footer-hover-fg);
  }

  --border-fg: 1px solid var(--fg);
  --border-bg: 1px solid var(--bg);
}

/* -------------------------------------------------------------------------------------------
-----------------------------------        Insider         -----------------------------------
------------------------------------------------------------------------------------------- */
body.template--insider {
  --fg: var(--white);
  --bg: var(--bg-green);
  --bg-1: var(--green);
  --bg-2: var(--light-green);
  --bg-3: var(--dark-green);

  --accent-fg: var(--red);
  --accent-bg: var(--red);
  --accent-hover-fg: var(--dark);

  --link-hover: var(--light-green);

  --button-fg: var(--white);
  --button-bg: var(--dark-green);
  --button-hover-fg: var(--dark);
  --button-hover-bg: var(--white);

  --icon: var(--white);
  --icon-hover: var(--light-green);

  --navi-fg: var(--dark);
  --navi-bg: var(--white);
  --navi-hover-fg: var(--bg-green);

  .mobile-nav {
    --navi-fg: var(--white);
    --navi-bg: var(--bg-green);
  }

  --overlay-fg: var(--dark);
  --overlay-bg: var(--white);

  --footer-fg: var(--dark);
  --footer-bg: var(--white);
  --footer-hover-fg: var(--dark-green);
  footer.footer {
    --link-hover: var(--footer-hover-fg);
  }

  --border-fg: 1px solid var(--fg);
  --border-bg: 1px solid var(--bg);

  .header__content-right .button {
    --button-bg: var(--red);
  }

  .cta-box,
  .highlighted-richtext {
    --accent-bg: var(--dark-green);
  }
}

/* -------------------------------------------------------------------------------------------
-----------------------------------          Regio          ----------------------------------
------------------------------------------------------------------------------------------- */
body.template--regio {
  --fg: var(--white);
  --bg: var(--dark);

  --accent-fg: var(--red);
  --accent-bg: var(--red);
  --accent-hover-fg: var(--dark);

  --link-hover: var(--red);

  --button-fg: var(--white);
  --button-bg: var(--red);
  --button-hover-fg: var(--dark);
  --button-hover-bg: var(--white);

  --icon: var(--white);
  --icon-hover: var(--red);

  --navi-fg: var(--dark);
  --navi-bg: var(--white);
  --navi-hover-fg: var(--red);

  @media (--media-mobile) {
    --navi-fg: var(--white);
    --navi-bg: var(--dark);
  }

  --overlay-fg: var(--dark);
  --overlay-bg: var(--white);

  --footer-fg: var(--white);
  --footer-bg: var(--red);
  --footer-hover-fg: var(--dark);

  footer.footer {
    --link-hover: var(--footer-hover-fg);
  }

  --border-fg: 1px solid var(--fg);
  --border-bg: 1px solid var(--bg);
}

.fg--red {
  color: var(--red);
}
