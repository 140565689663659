.region-map {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  @media (--media-sm-only) {
    gap: 0.5rem;
  }
  .map {
    path[data-region] {
      opacity: 0;
      transition: opacity 0.2s ease-in-out;

      cursor: pointer;
    }
    path.active {
      opacity: 1;
    }
  }
}
.region-map__map-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(var(--min-size, 260px), 1fr));
  gap: 2rem;

  @media (--media-md) {
    --min-size: 400px;
  }
  @media (--media-sm-only) {
    grid-template-columns: 1fr;
  }
}
.region-map__map {
  margin-block: auto;
  margin-inline: auto;
  width: 80%;
  @media (--media-md) {
    width: 100%;
  }
  @media (--media-sm-only) {
    display: none;
  }

  .map {
    margin-bottom: 1rem;
  }

  .legend {
    font-size: smaller;
    line-height: 1.2;
  }
}
.region-map__list {
  margin-block: auto;
  display: block;

  li {
    display: inline-block;
    margin: 0.375rem;
    @media (--media-sm-only) {
      display: block;
      margin: 1rem 0.375rem;
      width: 80%;
    }

    a {
      padding: 1rem;
      border: 1px solid black;
      border-radius: 0.75rem;

      &:hover {
        box-shadow:
          0.875rem 0.875rem 0.3125rem 0rem rgba(0, 0, 0, 0),
          0.5625rem 0.5625rem 0.3125rem 0rem rgba(0, 0, 0, 0.03),
          0.3125rem 0.3125rem 0.25rem 0rem rgba(0, 0, 0, 0.09),
          0.125rem 0.125rem 0.1875rem 0rem rgba(0, 0, 0, 0.15),
          0.0625rem 0.0625rem 0.125rem 0rem rgba(0, 0, 0, 0.18);
      }
    }
  }

  svg {
    --regio-logo--fill: black;
    display: block;
    max-width: 100%;
    height: 30px;
  }
}

.region-map__list__link {
  display: block;
}

.collapsible__content .region-map {
  .region-map__list img {
    @media (--media-md) {
      height: 51px;
    }
  }
}

.collapsible__content .region-map {
  @media (--media-md) {
    .region-map__map {
      width: 80%;
      margin-inline: auto;
    }
  }
}
