header.header {
  position: relative;
  width: 100%;
  z-index: var(--z-header);
  background-color: var(--bg);

  #menu-toggle:checked ~ & {
    .icon--close {
      display: block;
    }
    .icon--menu {
      display: none;
    }
  }
}

.header__content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: var(--header-content-height);
}

.header__content-right {
  display: flex;
  gap: 3rem;
  align-items: center;

  @media (--media-mobile) {
    display: none;
  }
}

.header__content-logo {
  flex-shrink: 0;
  flex-grow: 0;
  display: block;

  img {
    width: auto;

    --logo-height: 40px;
    --preaddon-height: calc(1.5 * var(--logo-height));
    --preaddon-offsettop: calc(-0.2 * var(--logo-height));
    --preaddon-padding: calc(2.7 * var(--logo-height));

    height: var(--logo-height);

    .header__content-right & {
      --logo-height: 28px;
      height: var(--logo-height);
    }

    @media (--media-mobile) {
      --logo-height: 28px;
      height: var(--logo-height);
    }
  }

  img.logo--preaddon {
    position: absolute;
    height: var(--preaddon-height);
    margin-top: var(--preaddon-offsettop);
    & + img {
      padding-left: var(--preaddon-padding);
    }
  }
}

nav.main-nav {
  position: sticky;
  width: 100%;
  top: 0;
  z-index: var(--z-header);
  background-color: var(--navi-bg);
  box-shadow: var(--box-shadow-2);
  @media (--media-mobile) {
    display: none;
  }
}

#menu-toggle {
  position: absolute;
  left: 0;
  top: 0;
  visibility: hidden;
  z-index: var(--z-hidden);
}

.main-nav__row {
  display: flex;
  justify-content: space-between;
  margin-right: calc(-1 * (var(--container-padding) / 2));
  margin-left: calc(-1 * (var(--container-padding) / 2));
}

ul.main-nav__list {
  display: inline-flex;

  @media (--media-mobile) {
    display: block;
  }
}

/* body.template--insider ul.main-nav__list {
  display: inline-flex;
} */

li.main-nav__item {
  position: relative;

  &:hover {
    ul.main-nav__sub-list:not(:empty) {
      display: flex;
    }
  }
}

.main-nav__item--login {
  ul.main-nav__sub-list:not(:empty) {
    left: auto;
    right: calc(-1 * (var(--container-padding) / 2));
  }
}

a.main-nav__link {
  cursor: pointer;

  &:hover,
  &.active {
    color: var(--navi-hover-fg);
  }
}

a.main-nav__link,
span.main-nav__link {
  color: var(--navi-fg);
  height: var(--nav-height);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
  --size: 1.5rem;
  padding-left: calc(var(--container-padding) / 2);
  padding-right: calc(var(--container-padding) / 2);
  width: auto;
}

.main-nav__space {
  color: var(--navi-fg);
  height: var(--nav-height);
  display: flex;
  align-items: center;
}

ul.main-nav__sub-list:not(:empty) {
  display: none;
  position: absolute;
  top: 100%;
  left: calc(-1 * (var(--container-padding) / 2));
  min-width: 100%;
  flex-direction: column;
  align-items: flex-start;
  background-color: var(--navi-bg);
  z-index: var(--z-menu);
  overflow: auto;
  max-height: calc(100vh - var(--header-height));
}

li.main-nav__sub-item {
  width: 100%;
}

a.main-nav__sub-link {
  cursor: pointer;
  white-space: nowrap;
  padding: 0.5rem var(--container-padding);
  color: var(--navi-fg);
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
  --size: 1.5rem;
  transition: color 200ms ease-in-out, background-color 200ms ease-in-out;

  &:hover {
    color: var(--navi-bg);
    background-color: var(--navi-fg);
  }

  &.active {
    color: var(--navi-hover-fg);
  }
}

a.main-nav__sub-link--two {
  padding-left: 3rem;
}

a.main-nav__sub-link--three {
  padding-left: 4.5rem;
}

.menu-toggle {
  cursor: pointer;
  height: 1.5rem;
  width: 1.5rem;

  @media (--media-lg) {
    display: none;
  }

  .icon--close {
    display: none;
  }
}

body.template--insider .main-nav .menu-toggle {
  display: block;
}

.menu-overlay {
  display: none;
  overflow: scroll;
  position: sticky;
  top: 0;
  right: 0;
  left: 0;
  background: var(--bg);
  z-index: var(--z-menu);
  padding-top: 1.5rem;
  padding-bottom: 20vh;
  height: 100vh;

  @media (--media-mobile) {
    #menu-toggle:checked ~ & {
      display: block;
    }
  }

  .header__content-logo {
    margin-bottom: 1.5rem;
  }

  .button {
    margin-bottom: 1.5rem;
  }
}

body.template--insider {
  #menu-toggle:checked ~ .menu-overlay {
    top: var(--nav-height);
    display: block;
  }
}

body:has(#menu-toggle:checked) {
  overflow: hidden;
}

.mobile-nav__item {
  margin-bottom: 2rem;
}

.mobile-nav__link {
  color: var(--navi-fg);
  padding: 0.5rem 0px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;

  width: auto;
  max-width: 100%;
}

a.mobile-nav__sub-link {
  cursor: pointer;
  padding: 0.5rem 1.5rem;
  color: var(--navi-fg);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  --size: 1.5rem;
  transition: color 200ms ease-in-out, background-color 200ms ease-in-out;
  max-width: 100%;

  &:hover {
    color: var(--navi-bg);
    background-color: var(--navi-fg);
  }
}

a.mobile-nav__sub-link--two {
  padding-left: 3rem;
}

a.mobile-nav__sub-link--three {
  padding-left: 4.5rem;
}

.messages-bar {
  width: 100%;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  background-color: var(--accent-bg);
  z-index: var(--z-header);
  pointer-events: none;

  @media (--media-mobile) {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
}
