body {
  font-family: "SRG_SSR";
  font-size: 20px;
  font-weight: 300;
  font-style: normal;
  line-height: 149%;
  color: var(--fg, green);
  background-color: var(--bg, pink);
  /* padding-top: var(--header-height); */
}

a {
  cursor: pointer;
  color: inherit;
  transition: color 200ms ease-in-out, border-color 200ms ease-in-out;

  &:hover {
    color: var(--link-hover);
  }

  p & {
    /* color: var(--fg); */
    text-decoration: underline;
  }
}

.button-more {
  color: var(--link-hover);
}

.grecaptcha-badge {
  display: none;
}

.hyphenate {
  hyphens: auto;
}

.inline-list {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem 2rem;
}

.highlighted {
  font-weight: 900;
}
