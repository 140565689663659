.search-results {
  margin-top: 2rem;
}

.search-results-meta {
  margin-top: 4rem;
}

.search-result {
  display: block;
}
