@import "./colors.css";

:root {
  --grid-gap: 1rem;
  --container-padding: 1.5rem;
  --layout-width: calc(1136px + (2 * var(--container-padding)));
  --content-width: 1136px;
  --text-width: 848px; /* 9 Cols */

  --small-content-width: calc(944px + (2 * var(--container-padding)));
  --small-text-width: 752px; /* 8 Cols */

  --header-content-height: 112px;

  @media (--media-mobile) {
    --header-content-height: 64px;
  }

  --nav-height: 56px;
  --header-height: calc(var(--header-content-height) + var(--nav-height));

  @media (--media-mobile) {
    --header-height: calc(var(--header-content-height));
  }

  --z-hidden: -5;
  --z-overlay: 5;
  --z-header: 10;
  --z-menu: 15;
  --z-admin: 20;

  --box-shadow-1: 4px 0px 16px 8px rgba(0, 0, 0, 0.25);
  --box-shadow-2: 0px 4px 16px 8px rgba(0, 0, 0, 0.25);

  --block-margin: 1.5rem;
  @media (--media-md) {
    --block-margin: 3.5rem;
  }
  @media (--media-lg) {
    --block-margin: 5rem;
  }
}
