.article-teaser-list {
  display: flex;
  flex-wrap: wrap;
  gap: var(--grid-gap);
}

.article-teaser-list__more {
  margin-top: 2.5rem;
}

.article-teaser {
  /* display: inline-block; */
  --fg: var(--dark);
  position: relative;
  --width: 100%;
  flex: 0 0 var(--width);
  max-width: var(--width);

  @media (--media-md) {
    --width: calc((100% / 2) - (1 * var(--grid-gap) / 2));
  }

  & ul.breadcrumbs {
    margin-top: 1.25rem;
    margin-bottom: 0;
  }
}

/* .article-teaser__link {
  --fg: var(--dark);
} */

.card {
  border-radius: 1rem;
  color: var(--fg);
  border: var(--bg);
  background: var(--white);

  box-shadow: var(--box-shadow-1);
  padding: 1.5rem;
  padding-bottom: 3.75rem;
  display: inline-block;
  height: 100%;
  transition: background-color 200ms ease-in-out, background-image 200ms
    ease-in-out, transform 150ms ease-in-out;

  &a:hover {
    transform: scale(1.025);
    color: var(--fg);
  }
}

.article-teaser__img-wrapper {
  position: relative;
}

.article-teaser__img {
  width: 100%;
  height: auto;
}

.article-teaser__media-icons {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  display: flex;
  gap: 0.5rem;
}

.article-teaser__title {
  margin-top: 1.25rem;
  .breadcrumbs ~ & {
    margin-top: 0.5rem;
  }
}

.article-teaser__content {
  margin-top: 1rem;
}

.article-teaser__more {
  display: inline-block;
  position: absolute;
  left: 1.5rem;
  bottom: 1.5rem;
}

.paginator {
  padding-top: 2rem;
  padding-bottom: 2rem;
  display: flex;
  justify-content: center;
  gap: 0.5rem;

  --link-hover: var(--button-hover-fg);

  a,
  span {
    display: inline-flex;
    align-items: center;
    padding: 0.5rem 1rem;
    background-color: var(--button-bg);
    border-radius: 0.5rem;

    &.active {
      color: var(--button-hover-fg);
      background-color: var(--button-hover-bg);
      font-weight: 500;
    }
  }

  /* a {
    &:hover {
      color: var(--button-hover-fg);
      background-color: var(--button-hover-bg);
    }
  } */
}

#list-container {
  opacity: 1;
  transition: opacity ease-in-out 0.3s;
  scroll-margin-top: 50vh;

  &.htmx-request {
    opacity: 0.1;
  }
}
