.icon {
  --size: 5rem;

  width: var(--size);
  height: var(--size);
  flex: 0 0 var(--size);
}

.icon--arrow-short,
.icon--arrow,
.icon--plus,
.icon--download,
.icon--arrow,
.icon--search,
.icon--login,
.icon--logout,
.icon--menu,
.icon--close,
.icon--filter,
.icon--remove {
  --size: 1.5rem;
}

.icon--point {
  --size: 0.25rem;
}

.icon--share,
.icon--round-right {
  --size: 2rem;
}

.icon--social,
.icon--audio,
.icon--video {
  --size: 2.5rem;
}

.icon--arrow-left {
  transform: rotate(180deg);
}
