/* -----------------------
         SRG_SSR
----------------------- */
@font-face {
  font-family: "SRG_SSR";
  font-weight: 900;
  font-style: normal;
  src: url("../fonts/SRGSSRType-Heavy.woff2") format("woff2"),
    url("../fonts/SRGSSRType-Heavy.woff") format("woff"),
    url("../fonts/SRGSSRType-Heavy.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "SRG_SSR";
  font-weight: bold;
  font-style: normal;
  src: url("../fonts/SRGSSRType-Bold.woff2") format("woff2"),
    url("../fonts/SRGSSRType-Bold.woff") format("woff"),
    url("../fonts/SRGSSRType-Bold.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "SRG_SSR";
  font-weight: bold;
  font-style: italic;
  src: url("../fonts/SRGSSRType-BoldItalic.woff2") format("woff2"),
    url("../fonts/SRGSSRType-BoldItalic.woff") format("woff"),
    url("../fonts/SRGSSRType-BoldItalic.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "SRG_SSR";
  font-weight: 500;
  font-style: normal;
  src: url("../fonts/SRGSSRType-Medium.woff2") format("woff2"),
    url("../fonts/SRGSSRType-Medium.woff") format("woff"),
    url("../fonts/SRGSSRType-Medium.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "SRG_SSR";
  font-weight: normal;
  font-style: normal;
  src: url("../fonts/SRGSSRType-Regular.woff2") format("woff2"),
    url("../fonts/SRGSSRType-Regular.woff") format("woff"),
    url("../fonts/SRGSSRType-Regular.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "SRG_SSR";
  font-weight: normal;
  font-style: italic;
  src: url("../fonts/SRGSSRType-Italic.woff2") format("woff2"),
    url("../fonts/SRGSSRType-Italic.woff") format("woff"),
    url("../fonts/SRGSSRType-Italic.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "SRG_SSR";
  font-weight: 300;
  src: url("../fonts/SRGSSRType-Light.woff2") format("woff2"),
    url("../fonts/SRGSSRType-Light.woff") format("woff"),
    url("../fonts/SRGSSRType-Light.ttf") format("truetype");
  font-display: swap;
}

/* -----------------------
      SRG_SSR_SERIF
----------------------- */

@font-face {
  font-family: "SRG_SSR_SERIF";
  font-weight: 500;
  font-style: normal;
  src: url("../fonts/SRGSSRTypeSerif-Medium.woff2") format("woff2"),
    url("../fonts/SRGSSRTypeSerif-Medium.woff") format("woff"),
    url("../fonts/SRGSSRTypeSerif-Medium.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "SRG_SSR_SERIF";
  font-weight: normal;
  font-style: normal;
  src: url("../fonts/SRGSSRTypeSerif-Regular.woff2") format("woff2"),
    url("../fonts/SRGSSRTypeSerif-Regular.woff") format("woff"),
    url("../fonts/SRGSSRTypeSerif-Regular.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "SRG_SSR_SERIF";
  font-weight: 300;
  font-style: normal;
  src: url("../fonts/SRGSSRTypeSerif-Light.woff2") format("woff2"),
    url("../fonts/SRGSSRTypeSerif-Light.woff") format("woff"),
    url("../fonts/SRGSSRTypeSerif-Light.ttf") format("truetype");
  font-display: swap;
}
