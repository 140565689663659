.edit-tools {
  position: fixed;
  z-index: var(--z-admin);
  right: 0.5rem;
  bottom: 0.5rem;
  background: var(--button-bg);
  opacity: 0.5;
  display: flex;
  align-items: center;
  font-weight: bold;
  border-radius: 0.25rem;
  color: var(--button-fg);
  padding: 0.125em 0.25em;
  font-size: 16px;

  span {
    padding: 0.125em 0.25em;
  }

  a {
    display: none;
    padding: 0.125em 0.25em;

    &:hover {
      color: var(--button-hover-fg);
    }
  }

  &:hover {
    opacity: 1;
  }

  &:hover a {
    display: inline-block;
    opacity: 1;
  }
}

@media (--media-sm-only) {
  #djDebug {
    display: none;
  }
}
