.thematic-world--srgd {
  --tw-primary: #af001d;
  --tw-secondary: #af001d;
  --tw-tertiary: #af001d;
}

.thematic-world--ombuds_office {
  --tw-primary: #0a2f78;
  --tw-secondary: #1d7ac7;
  --tw-tertiary: #1d4698;
}

.thematic-world--audience_council {
  --tw-primary: #45056e;
  --tw-secondary: #7c0fc2;
  --tw-tertiary: #610ba7;
}

.thematic-world--insider {
  --tw-primary: #135658;
  --tw-secondary: #27b0b4;
  --tw-tertiary: #1b7b7e;
}

article[class*="thematic-world"] {
  .article-teaser__img {
    border-bottom: 10px solid var(--tw-secondary);
  }
  .article-teaser__more {
    color: var(--tw-tertiary);
  }
  .featured__item-content,
  .slider__item-content {
    border-top: 10px solid var(--tw-secondary);
  }
}

body[class*="thematic-world"] header.header {
  background-color: var(--tw-primary);
}

body[class*="thematic-world"] {
  --accent-fg: var(--tw-primary);
  --accent-bg: var(--tw-primary);
  --accent-hover-fg: var(--tw-secondary);

  --link-hover: var(--tw-secondary);
  --button-bg: var(--tw-primary);
  --icon-hover: var(--tw-secondary);
  --navi-hover-fg: var(--tw-secondary);

  --footer-bg: var(--red);

  .paginator {
    --link-hover: var(--tw-secondary);
  }
}

body[class*="thematic-world"] header .button {
  --button-bg: var(--red);
}
