body.template--insider .related-section {
  --button-fg: var(--white);
  --button-bg: var(--dark);
  color: var(--fg);

  &:nth-child(3n + 1) {
    --fg: var(--white);
    --button-fg: var(--dark);
    --button-bg: var(--white);
    --button-hover-fg: var(--white);
    --button-hover-bg: var(--dark);
    --link-hover: var(--dark);

    background-color: var(--bg-1);
  }
  &:nth-child(3n + 2) {
    --fg: var(--dark);
    --link-hover: var(--white);
    background-color: var(--bg-2);
  }
  &:nth-child(3n) {
    --fg: var(--dark);
    --link-hover: var(--white);
    background-color: var(--bg-3);
  }

  .button {
    margin-top: 3.5rem;
  }
}
