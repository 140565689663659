.profile-memberships-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--grid-gap);
  grid-auto-rows: 1fr;
  --fg: var(--white);
  color: var(--fg);
}

.profile-memberships__item {
  max-width: var(--width);
  border: var(--bg);
  padding: 1rem;
  border-radius: 1rem;
  box-shadow: var(--box-shadow-1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 1rem;
}

.profile-memberships__item-logo {
  height: 2rem;
  display: block;
  margin-bottom: 1rem;
}
