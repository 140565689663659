.teaser-section {
  color: var(--fg);
  &:nth-child(even) {
    background-color: var(--white);
    --fg: var(--dark);

    /* inverted color scheme for some slider with white bg */
    .icon--social {
      --fg: var(--white);
    }
    .button-icon--round {
      color: var(--icon);
      background-color: var(--icon-hover);

      &:hover {
        color: var(--dark);
        background-color: var(--icon);
      }
    }
  }

  &:nth-child(odd) {
    background-color: var(--accent-bg);
    --link-hover: var(--dark);

    .teaser__inner {
      @media (--media-md) {
        flex-direction: row-reverse;
      }
    }
  }

  &:nth-last-child(odd) {
    border-bottom: 1rem solid var(--white);
  }
}

.teaser-section .region-map {
  color: var(--dark);
  background-color: var(--white);
  border: 1px solid var(--dark);
  padding: 1rem;
  width: auto;
  @media (--media-md) {
    padding: 2.5rem 4rem;
  }
}

details.collapsible .teaser-section.region-map {
  padding: 0;
}

.teaser__inner {
  display: flex;
  gap: 1.5rem;
  flex-direction: column;

  @media (--media-md) {
    flex-direction: row;
  }
}

.teaser__media,
.teaser__body {
  flex: 1;
}

.teaser__media {
  position: relative;
}

.teaser__media-play {
  position: absolute;
  height: 3rem;
  width: 3rem;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.teaser__media-player {
  width: 100%;
  display: block;
}

.teaser__image {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
}

.teaser__more {
  display: inline-block;
  margin-top: 1rem;
}

.teaser h1 {
  margin-top: 0px;
}
