section.article-detail-section {
}

.article-detail__title {
  margin-top: 3rem;
}

.article-detail__image-wrapper {
  position: relative;
}

.article-detail__image {
  width: 100%;
}

.article-detail__media-caption {
  margin-top: 0.75rem;
}

ul.article-detail__social-share {
  display: block;
  position: absolute;
  left: calc(100% + 1rem);
  bottom: 0px;
  color: var(--fg);
  display: flex;
  gap: 1rem;
  flex-direction: column;

  @media (--media-mobile) {
    position: static;
    flex-direction: row;
    margin-top: 1rem;
  }
}

li.article-detail__social-share-item {
}

.article-detail__social-share-link {
  display: block;
  width: 2rem;
  height: 2rem;

  &:hover {
    color: var(--link-hover);
  }
}

.article-detail__signup_buttons .button {
  margin-right: 20px;

  &:last-child {
    margin-right: 0;
  }
}

.article-detail__content {
  /* > .container:last-child {
    margin-bottom: 5rem;

    @media (--media-sm-only) {
      margin-bottom: 2.5rem;
    }
  } */

  > div:not(.container) {
    margin-bottom: 0px !important;
  }

  > .container {
    margin-top: 5rem;

    @media (--media-sm-only) {
      margin-top: 2.5rem;
    }
  }

  > section {
    margin-top: 5rem;

    @media (--media-sm-only) {
      margin-top: 2.5rem;
    }

    + section {
      margin-top: 0px;
    }
  }
}

.comments {
  border-top: var(--border-fg);
  border-bottom: var(--border-fg);
  padding: 2rem 0px;
  margin-top: 3.5rem;
}

.main section.comments-section {
  margin-top: var(--block-margin);
  margin-bottom: var(--block-margin);
}

.comments__loading-message {
}

/* .comments__count-message {
} */

.comments__empty-message {
}

.comments__list {
}

article.comment-item:not(:last-child) {
  padding-bottom: 2rem;
  margin-bottom: 2rem;
  border-bottom: var(--border-fg);
}

.comment-item__meta {
  margin-bottom: 1rem;
}

.comment-item__text {
}

.form--comments {
}

.tags {
  border-bottom: var(--border-fg);
  padding-bottom: 1.5rem;
}

.tags__title {
  margin-bottom: 1.5rem;
}

ul.tags__list {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

li.tags__item {
  display: inline-block;
}

a.tags__link {
  display: inline-block;
  padding: 0.5rem 0.75rem;
  border-radius: 1rem;
  border: var(--border-fg);
  background: var(--fg);
  color: var(--bg);
}

.tags_ombudsfall {
  margin-top: 2.5rem;
}
