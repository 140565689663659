ul.breadcrumbs {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  align-items: center;
  margin-bottom: var(--block-margin);

  .breadcrumbs__wrapper & {
    margin-bottom: 0;
  }
}

.breadcrumbs__item {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.breadcrumbs__text {
  white-space: nowrap;
  &.active {
    /* color: var(--button-bg); */
  }
}

/* .breadcrumbs__item--has-silbings {
} */

.breadcrumbs__wrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.breadcrumbs__filter {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
}

.container--breadcrumbs-small-margin {
  --block-margin: 2.5rem;

  @media (--media-mobile) {
    --block-margin: 1.5rem;
  }

  @media (--media-sm-only) {
    --block-margin: 1rem;
  }
}
