/* .flow > * {
  @media (--media-lg) {
    max-width: 61.375rem;
    margin-right: auto;
  }
} */

:root {
  --flow: 1em;
}

.flow > * {
  /* margin-block-start: var(--flow, 1em); */
  margin-block-end: max(calc(var(--flow) * 0.5), 1em);
}

.flow > blockquote {
  margin-block-end: 4rem;
}

.flow > h1 {
  margin-top: 1.5em;
}

.flow {
  h2,
  h3,
  h4 {
    --flow: 2em;
    margin-block-start: var(--flow);
  }
}

.prose :is(h2 + *, h3 + *, h4 + *) {
  --flow: 0.75em;
}
