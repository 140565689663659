.gallery {
  .gallery_slide {
    position: relative;
    margin-bottom: 4rem;
    .description {
      position: absolute;
      bottom: -3rem;
      font-size: smaller;

      p {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        line-height: 1.2;
      }
    }
  }
  .splide__pagination {
    bottom: 5rem;
  }
  .splide__arrow {
    transform: translateY(-3rem);
  }
  .splide__pagination__page {
    margin: 0.3rem;
    opacity: 0.9;
    width: 1rem;
    height: 1rem;
    background-color: #fff;
    &.is-active {
      background-color: var(--red);
      transform: none;
    }
  }
}
