/* -----------------------
         metainfos
----------------------- */

.metainfos {
  border-top: var(--border-fg);
  padding: 1.5rem 0px;
}

.metainfos__title {
  margin-bottom: 1.5rem;
}

/* -----------------------plugins
       aligned-image
----------------------- */

.aligned-image {
  display: flex;
  gap: 1.5rem;
  flex-direction: column;

  img {
    width: 100%;
  }

  margin-block: 2em;

  &:first-child :is(h1, h2, h3, h4) {
    margin-top: auto;
  }

  @media (--media-md) {
    flex-direction: row;
  }
}

/* -----------------------
          image
----------------------- */

.image--small {
  img,
  figcaption {
    max-width: 520px;
  }
}

.image--medium {
  img,
  figcaption {
    max-width: var(--small-text-width);
  }
}

.image__image--medium,
.image__image--small {
  width: 100%;
}

.image > a {
  display: block;
}

/* -----------------------
        download
----------------------- */

.download-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  text-decoration: underline;
}

/* -----------------------
  collapsible / details
----------------------- */

details.collapsible {
  --collapsed: auto;
  --expanded: auto;
  border-top: var(--border-fg);
  padding-bottom: 2rem;
  border-collapse: collapse;
  transition: height 300ms ease-in-out;
  height: var(--collapsed);
  overflow: hidden;

  .icon {
    position: absolute;
    top: 2rem;
    right: 0px;
    transition: transform 150ms ease-in-out;
  }

  cursor: default;
  text-decoration: none;

  &:not([open]):hover {
    cursor: pointer;
    text-decoration: underline;
  }

  &[open] {
    height: var(--expanded);
    .icon {
      transform: rotate(45deg);
      cursor: pointer;
    }
  }

  &:last-of-type {
    border-bottom: var(--border-fg);
  }
}

/* Team Members shadow fix */
details.collapsible {
  .teaser-section,
  .link-magazine-entries,
  .team {
    padding-inline: var(--container-padding);
  }
}

/* teaser section fix */
.collapsible .teaser-section {
  h1 {
    font-size: 36px;
    margin-top: 0px;
  }
  .teaser__inner,
  &:nth-child(odd) .teaser__inner {
    flex-direction: column;
    @media (--media-md) {
      flex-direction: column;
    }
  }
}

summary.collapsible__summary {
  position: relative;
  list-style: none;
  padding-top: 2rem;
  padding-right: 2rem;

  &::-webkit-details-marker {
    display: none;
  }
}

.collapsible__content {
  /* display: flex;
  gap: var(--grid-gap);*/
  padding-top: 2rem;
  width: 100%;
}

.collapsible__summary__text {
  grid-area: "text";
}

.collapsible__summary__image {
  grid-area: "image";
  .image__image {
    max-height: 12.5rem;
    object-fit: cover;
    margin-block: auto;
  }
}

.collapsible__summary:has(.collapsible__summary__image) {
  display: grid;
  gap: 1.5rem;
  @media (--media-md) {
    grid-template-areas: "image text text";
    grid-template-columns: 1fr 2fr;
  }
}

/* -----------------------
       teaser-tiles
----------------------- */

.teaser-tiles {
  display: grid;
  grid-auto-rows: 1fr;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: var(--grid-gap);

  @media (--media-lg) {
    grid-template-columns: repeat(3, minmax(300px, 1fr));
  }
}

article.teaser-tiles__item {
  --fg: var(--dark);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 1rem;
  box-shadow: var(--box-shadow-1);
  background-color: var(--bg);
  overflow: hidden;
  transition: background-color 200ms ease-in-out, background-image 200ms
    ease-in-out, transform 150ms ease-in-out;
  aspect-ratio: 1 / 1;

  &:hover {
    transform: scale(1.05);
    background-color: var(--bg-hover);
    color: var(--fg);
    a {
      color: var(--fg);
    }
  }
}

article.teaser-tiles__item--negative {
  .teaser-tiles__item-title {
    color: var(--white);
    &:hover {
      color: var(--dark);
    }
  }
}

.teaser-tiles__item-link {
  padding: 1.5rem;
  display: block;
  height: 100%;
}

.teaser-tiles__item-title {
  margin-bottom: 0px;
  font-size: 2.25rem;
  word-break: break-word;
}

.teaser-tiles__item-content {
  height: 100%;
  width: 100%;
  aspect-ratio: 1 / 1;
}

/* -----------------------
          Team
----------------------- */

.container--team {
  margin-bottom: var(--block-margin);
}

.team {
  --fg: var(--white);
  --grid-gap: 1.5rem;
  color: var(--fg);
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
  gap: var(--grid-gap);
}

.team__member {
  border: var(--bg);
  padding: 1rem;
  border-radius: 1rem;
  box-shadow: var(--box-shadow-1);
  display: flex;
  flex-flow: column;

  img {
    margin-inline: auto;
  }

  h4 {
    margin-block: 1rem;
  }
}

/* -----------------------
         Slider
----------------------- */

/* .slider {
  position: relative;
  min(var(--content-width), 80%);
  calc((100% - min(var(--content-width), 80%)) / 2 - 1.5rem);
} */

article.slider__item {
  overflow: hidden;
  /* opacity: 0.5;
  &.is-active {
    opacity: 1;
  } */
}

.slider__item-link {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.slider__item-image {
  width: 100%;

  object-fit: cover;
  user-select: none;
  pointer-events: none;
  border-radius: 1rem 1rem 0 0;
  aspect-ratio: 16 / 9;

  @media (--media-md) {
    border-radius: 1rem;
    height: 100%;
    aspect-ratio: unset;
  }
}

.slider__item-content {
  --fg: var(--dark);
  color: var(--fg);
  padding: 0.5rem;
  background-color: var(--white);
  flex: 1 1 auto;
  box-shadow: var(--box-shadow-1);
  hyphens: auto;
  border-radius: 0 0 1rem 1rem;

  @media (--media-md) {
    border-radius: 0;
    width: 90%;
    position: absolute;
    left: 0px;
    bottom: 1.5rem;
    /* hyphens: none; */
  }

  @media (--media-lg) {
    padding: 1rem;
    width: 50%;
    bottom: 2rem;
  }
}

.slider__button {
  --position: calc((100% - min(var(--content-width), 80%)) / 2 + 1.5rem);
}

.slider__button--prev {
  left: var(--position);
}
.slider__button--next {
  right: var(--position);
}

/* -----------------------
      Slider SoMe
----------------------- */

.slider--some {
  .slider__item-image {
    aspect-ratio: 1 / 1;
    border-radius: 0;
  }

  .icon--social {
    position: absolute;
    left: 1rem;
    top: 1rem;
    z-index: 2;
    --size: 3rem;
    color: var(--fg);
  }

  .slider__button {
    --position: 1.5rem;
  }

  .slider__button--prev {
    left: var(--position);
  }
  .slider__button--next {
    right: var(--position);
  }
}

/* -----------------------
         Featured
----------------------- */

.featured {
  display: grid;
  gap: 1.5rem;
  grid-template-areas:
    "bignews"
    "smallnews1"
    "smallnews2";
  grid-template-rows: 1fr 1fr 1fr;

  @media (--media-md) {
    grid-template-areas:
      "bignews bignews"
      "smallnews1 smallnews2";
    grid-template-rows: 1fr 0.5fr;
    grid-template-columns: 1fr 1fr;
  }
  @media (--media-lg) {
    grid-template-areas:
      "bignews bignews smallnews1"
      "bignews bignews smallnews2";
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.featured__item-link {
  overflow: hidden;
  display: flex;
  flex-flow: column;
  position: relative;
  height: 100%;
  border-radius: 1rem;

  transition: transform 150ms ease-in-out, color 200ms ease-in-out;

  @media (--media-lg) {
    &:hover {
      transform: scale(1.025);
    }
  }
}

.featured__item-image {
  width: 100%;
  object-fit: cover;
  height: 100%;
}

.featured__item-content {
  --fg: var(--dark);
  color: var(--fg);
  padding: 1rem;
  background-color: var(--white);
  width: 100%;
  box-shadow: var(--box-shadow-1);

  @media (--media-lg) {
    position: absolute;
    left: 0px;
    bottom: 1.5rem;
    width: 60%;
  }
}

article.featured__item {
  grid-area: bignews;

  /* clamp highlight teaser titles to 2 lines. IE will play along */
  .featured__item-title {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

article.featured__item:nth-child(2) {
  grid-area: smallnews1;

  .featured__item-content {
    /* line-height: 100%; */
    @media (--media-lg) {
      padding: 0.25rem 1rem;
      bottom: 1rem;
      width: 90%;
    }
  }
  featured__item-title {
    line-height: 130%;
  }
}

article.featured__item:nth-child(3) {
  grid-area: smallnews2;

  .featured__item-content {
    /* line-height: 129%; */
    @media (--media-lg) {
      padding: 0.25rem 1rem;
      bottom: 1rem;
      width: 90%;
    }
  }
  featured__item-title {
    line-height: 130%;
  }
}

/* -----------------------
       Testimonials
----------------------- */

.testimonials-section {
  --fg: var(--accent-fg);
  --bg: var(--white);
  color: var(--fg);
  background-color: var(--bg);
}

/* .testimonials {
} */

li.testimonials__item {
  > div {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 30px;

    @media (--media-md) {
      gap: 60px;
    }

    @media (--media-sm-only) {
      flex-wrap: wrap;
    }
  }
}

.quote__item {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.testimonials__item-image {
  --width: 100%;
  flex: 0 0 var(--width);
  width: var(--width);
  max-width: 100%;

  img {
    border-radius: 50%;
    background-color: pink;
    aspect-ratio: 1 / 1;
  }

  @media (--media-md) {
    --width: calc((100% / 4) - 30px);
  }
}

.testimonials__item-content {
  --fg: var(--dark);
  color: var(--fg);
  width: 100%;
  flex: 1 1 100%;

  @media (--media-md) {
    flex: 1 1 auto;
  }
}

.testimonials__item-quote,
.quote__item-quote {
  hyphens: auto;
  margin-bottom: 0.33em;
}

.testimonials__item-autor,
.quote__item-source {
  line-height: 1.2;
}

.testimonials__button--next {
  right: 20px;
}

.testimonials__button--prev {
  left: 20px;
  svg {
    transform: none;
  }
}

/* -----------------------
          CTA Box
----------------------- */
.cta-box {
  background-color: var(--accent-bg);
  padding: 2rem;
}

.cta-box__inner {
  display: flex;
  gap: 1.25rem;
  flex-wrap: wrap;

  @media (--media-md) {
    flex-wrap: nowrap;
  }
}

.cta-box__title {
  hyphens: auto;
}

@media (--media-md) {
  .cta-box__image {
    flex: 1;
  }

  .cta-box__content {
    flex: 1;
  }
}

.cta-box__content .button {
  margin-top: 1.25rem;
}

/* -----------------------
          CTA Teaser
----------------------- */

.teaser-cta {
  --width: 100%;
  --fg: var(--white);
  flex: 0 0 var(--width);
  max-width: var(--width);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border: 0.5rem solid var(--red);
  padding: 2.25rem;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  flex-direction: column;
  gap: 1.5rem;
  transition: background-color 200ms ease-in-out, background-image 200ms
    ease-in-out, transform 150ms ease-in-out;

  @media (--media-md) {
    --width: calc((100% / 2) - (1 * var(--grid-gap) / 2));
  }

  &:hover {
    transform: scale(1.025);
    color: var(--fg);
  }
}

/* -----------------------
       CTA Section
----------------------- */

.cta {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border: 1rem solid var(--red);
  padding-top: 6.8rem;
  padding-bottom: 5.44rem;
}

.container--small .cta {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.cta__content {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-direction: column;
  gap: 1.5rem;

  h1 {
    font-size: 2rem;
    line-height: 1.2;
    font-weight: normal;
    @media (--media-md) {
      font-size: 2.5rem;
    }
    @media (--media-lg) {
      font-size: 3.125rem;
    }
  }
}

.teaser-cta,
.cta__content {
  .button--cta {
    display: flex;
    gap: 1rem;
    font-weight: normal;
    .icon {
      --fill: var(--white);
      color: var(--dark);
    }
    &:hover,
    &:focus-visible {
      .icon {
        color: var(--white);
        --fill: var(--dark);
      }
    }
  }
}

/* -----------------------
        Gallery
----------------------- */

.gallery_slide img {
  width: 100%;
  height: auto;
}

.gallery_slider .splide__arrow.splide__arrow--prev .icon {
  transform: none;
}

.gallery__progress {
  background: var(--white);
}

.gallery__progress-bar {
  background: var(--accent-bg);
  height: 2px;
  transition: width 400ms ease;
  width: 0;
}

.gallery__thumbnail {
  opacity: 0.5;
  border: none !important;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &.is-active {
    opacity: 1;
  }
}

.gallery h2 {
  margin-bottom: 1em;
}

.highlighted-richtext {
  background-color: var(--accent-bg);
  padding: 2rem;

  a:hover,
  a:focus-visible {
    color: var(--accent-hover-fg);
  }
}

/* .splide__arrow { */
/*   display: none; */
/* } */
/* @media (--media-md) { */
/*   .splide__arrow { */
/*     display: flex; */
/*   } */
/* } */

/* -----------------------
          Video
----------------------- */

.video {
  width: 100%;
  margin-inline: auto;

  video {
    display: block;
    width: 100%;
  }
}

/* -----------------------
          Audio
----------------------- */

.audio-player {
  width: 100%;
}

/* ---------------------------------
         LINK Magazine Entry
--------------------------------- */

.link-magazine-entries {
  --fg: var(--white);
  --grid-gap: 1.5rem;
  color: var(--fg);
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(10rem, 13rem));
  gap: var(--grid-gap);
}

.link-magazine-entry {
  border: var(--bg);
  padding: 1rem;
  border-radius: 1rem;
  box-shadow: var(--box-shadow-1);
  display: flex;
  flex-flow: column;

  img {
    margin-inline: auto;
  }

  h4 {
    margin-block: 1rem;
  }
}

/* -----------------------
      Event downloads
----------------------- */

.events-downloads {
  border-top: var(--border-fg);
  padding: 1.5rem 0px;
}

.events-downloads__title {
  margin-bottom: 1.5rem;
}

/* -----------------------
    Event registration
----------------------- */
.event-registration {
  border-top: var(--border-fg);
  padding: 1.5rem 0px;

  .form__item + .container--richtext {
    .prose {
      margin-inline: unset;
    }
  }
}

.event-registration__title {
  margin-bottom: 1.5rem;
}
