/* -----------------------
         SRG_SSR
----------------------- */

h1,
.h1 {
  font-size: 2rem;
  font-style: normal;
  font-weight: 900;
  line-height: 115.6%;
  margin-bottom: 0.75em;

  @media (--media-md) {
    font-size: 2.5rem;
    margin-bottom: 1em;
  }
  @media (--media-lg) {
    font-size: 3.125rem;
  }

  .prose & {
    margin-bottom: 2rem;
    margin-top: var(--block-margin);
  }

  @media (--media-mobile) {
    font-size: 36px;
  }
}

h2,
.h2 {
  font-size: 36px;
  font-style: normal;
  font-weight: 900;
  line-height: 130%;

  @media (--media-mobile) {
    font-size: 28px;
  }
}

.testimonials__item-autor,
.quote__item-source,
blockquote p cite {
  font-size: 34px;
  font-style: italic;
  font-weight: 400;
  line-height: 100%;
  font-family: "SRG_SSR";

  @media (--media-mobile) {
    font-size: 28px;
  }
}

h3,
.h3 {
  font-size: 28px;
  font-style: normal;
  font-weight: 900;
  line-height: 36.4px;

  @media (--media-mobile) {
    font-size: 26px;
  }
}

h4,
.h4,
.button.button--cta,
p.lead {
  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
}

.article-teaser__more,
.main-nav__link,
.mobile-nav__link,
.button {
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 1em;
  white-space: nowrap;
}

article.featured__item:nth-child(2) .featured__item-title,
article.featured__item:nth-child(3) .featured__item-title,
.filter__title,
.breadcrumbs__filter,
.footer__newsletter,
.teaser__more,
a.main-nav__sub-link,
a.mobile-nav__sub-link,
a.meta-nav__link {
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 149%;
}

.filter__button {
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5rem;
}

/* .prose p, */
/* .comment-item__text p, */
input,
textarea,
select {
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 149%;
}

strong {
  font-weight: 900;
}

figcaption,
.figcaption,
.breadcrumbs__text,
.comment-item__meta,
.errorlist {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 161.25%;
}

a.tags__link {
  text-transform: uppercase;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 1rem;
  letter-spacing: 0.14px;
}

/* -----------------------
      SRG_SSR_SERIF
----------------------- */

.cta__title,
.testimonials__item-quote,
.quote__item-quote,
blockquote p {
  font-family: "SRG_SSR_SERIF";
  font-size: 2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;

  @media (--media-md) {
    font-size: 2.5rem;
  }
  @media (--media-lg) {
    font-size: 3.125rem;
  }
}

.teaser-cta__title {
  font-family: "SRG_SSR_SERIF";
  font-size: 1.625rem;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 38.4px */

  @media (--media-md) {
    font-size: 2rem;
  }
}
