.container {
  max-width: var(--layout-width);
  margin-left: auto;
  margin-right: auto;
  padding-left: var(--container-padding);
  padding-right: var(--container-padding);
  width: 100%;

  .row {
    /* background-color: rgb(255, 0, 111); Remove ROW Class */
  }

  .container {
    /* background-color: yellowgreen;  TODO: ONLY FOR TESTING */
    padding-left: 0px;
    padding-right: 0px;
  }
}

body.layout--small .container--small {
  max-width: var(--small-content-width);

  .prose {
    max-width: var(--small-text-width);
    margin-inline: auto;
  }
}

.prose {
  max-width: var(--text-width);
}
/* .container--small {
  max-width: var(--small-text-width);
} */

/* hr:last-of-type {
  display: none;
} */

.col-4,
.col-6,
.col-8 {
  --width: 100%;
  flex: 0 0 var(--width);
  max-width: var(--width);
}

.col-4 {
  @media (--media-md) {
    --width: calc((100% / 3) - (2 * var(--grid-gap) / 3));
  }
}
.col-6 {
  @media (--media-md) {
    --width: calc((100% / 2) - (1 * var(--grid-gap) / 2));
  }
}
.col-8 {
  @media (--media-md) {
    --width: calc((100% / 1.5) - (1 * var(--grid-gap) / 2));
  }
}

.main {
  > .container:first-child {
    margin-top: 2.5rem;

    @media (--media-mobile) {
      margin-top: 1.5rem;
    }

    @media (--media-sm-only) {
      margin-top: 1rem;
    }
  }

  > .container:last-child {
    margin-bottom: var(--block-margin);
  }

  > div:not(.container) {
    margin-bottom: 0px !important;
  }

  > .container {
    margin-top: var(--block-margin);
  }

  > .container--flow {
    margin-top: 0;
  }

  > section {
    margin-top: var(--block-margin);

    + section.teaser-section,
    + section.testimonials-section,
    + section.cta-section,
    + section.related-section {
      margin-top: 0px;

      @media (--media-mobile) {
        margin-top: 0px;
      }
      @media (--media-sm-only) {
        margin-top: 0px;
      }
    }

    &:first-child {
      margin-top: 0px;
      padding-top: 2.5rem;
      padding-bottom: 2.5rem;

      @media (--media-mobile) {
        margin-top: 0px;
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
      }
      @media (--media-sm-only) {
        margin-top: 0px;
        padding-top: 1rem;
        padding-bottom: 1rem;
      }
    }
  }
}

.container:not(.container--flow) + .container--flow {
  margin-top: var(--block-margin);
}

.section + .container {
  margin-top: var(--block-margin);
}

.section {
  padding-top: 4rem;
  padding-bottom: 7.5rem;

  @media (--media-mobile) {
    padding-top: 2rem;
    padding-bottom: 3rem;
  }
  @media (--media-sm-only) {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
}

.section--fake {
  padding-bottom: 4rem;

  @media (--media-mobile) {
    padding-bottom: 2rem;
  }
  @media (--media-sm-only) {
    padding-bottom: 1rem;
  }
}
